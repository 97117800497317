<template>
    <div style="width:100%;float:left;">
      <CCard>
        <CCardHeader>
          Missing Front Images
        </CCardHeader>
        <CCardBody>
          <CButton
            color="primary"
            class="mb-2"
            :href="csvFront" 
            download="missing_front.csv"
            target="_blank"
          >
            Download current items (.csv)
          </CButton>
          <CDataTable
            hover
            :items="front"
            :fields="fields"
            :items-per-page="30"
            pagination
            column-filter
            table-filter
            sorter
          >
          </CDataTable>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          Missing Back Images
        </CCardHeader>
        <CCardBody>
          <CButton
            color="primary"
            class="mb-2"
            :href="csvBack" 
            download="missing_back.csv"
            target="_blank"
          >
            Download current items (.csv)
          </CButton>
          <CDataTable
            hover
            :items="back"
            :fields="fields"
            :items-per-page="30"
            pagination
            column-filter
            table-filter
            sorter
          >
          </CDataTable>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          Missing Customer Style
        </CCardHeader>
        <CCardBody>
          <CButton
            color="primary"
            class="mb-2"
            :href="csvMissing" 
            download="missing_customer_style.csv"
            target="_blank"
          >
            Download current items (.csv)
          </CButton>
          <CDataTable
            hover
            :items="missing"
            :fields="missing_fields"
            :items-per-page="30"
            pagination
            column-filter
            table-filter
            sorter
          >
          </CDataTable>
        </CCardBody>
      </CCard>
    </div>
</template>
<script>
var methods = {}

methods.getMissing = function () {
  axios.post(this.$apiAdress + '/api/getMissingImages?token=' + localStorage.getItem('api_token'))
  .then(response => {
    console.log(response)
    this.front = response.data.front
    this.back = response.data.back
    this.missing = response.data.missing
  })
}

export default {
    // ===Component name
    name: "ImageMissing",
    // ===Props passed to component
    props: {},
    // ===Components used by this component
    components: {

    },
    // ====component Data properties
    data(){
        return{
          front: [],
          fields: ['customer_style', 'customer_number', 'customer_color', 'booking_style', 'booking_color', 'size'],
          missing_fields: ['booking_style', 'booking_color', 'customer_color', 'size'],
          back: [],
          missing: []
        }
    },
    // ===Code to be executed when Component is mounted
    mounted() {
        this.getMissing()
    },
    // ===Computed properties for the component
    computed: {
      csvFrontContent () {
        return this.front.map(item => Object.values(item).join(',')).join('\n')
      },
      csvFront () {
        return 'data:text/csv;charset=utf-8,SEP=,%0A' + encodeURIComponent(this.csvFrontContent)
      },
      csvBackContent () {
        return this.back.map(item => Object.values(item).join(',')).join('\n')
      },
      csvBack () {
        return 'data:text/csv;charset=utf-8,SEP=,%0A' + encodeURIComponent(this.csvBackContent)
      },
      csvMissingContent () {
        return this.missing.map(item => Object.values(item).join(',')).join('\n')
      },
      csvMissing () {
        return 'data:text/csv;charset=utf-8,SEP=,%0A' + encodeURIComponent(this.csvMissingContent)
      }
    },
    // ===Component methods
    methods: methods
}
</script>
<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped></style>
